
import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom';
import  styles from "./styles/passwordPage.module.css"
import Logo  from '../assets/cp365v.png';
import { Toaster, toast} from 'react-hot-toast';

import { api } from '../api.js';

const PasswordPage = () => {

    
    const [email, setEmail] = useState('');
   const navigate = useNavigate()
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
      };
    

    const handleSubmit = async (event) => {
        event.preventDefault();
      
       
    
        try {
          const response = await api.post(`/users/send-otp?type=resetPassword`, { email },);
      
          
          if (response && response.data) {
      
            
            if (response.data.status === true) {
              
              toast('Email sent successfully!', { type: 'success' });
      
              
              navigate(`/verify?email=${email}`);
            } else {
              
              toast(response.data.message || 'An error occurred', { type: 'error' });
            }
          } else {
            console.error('Invalid response format');
          }
        } catch (error) {
          console.error('Error:', error);
          
          toast(error.response?.data?.message || 'An error occurred', { type: 'error' });
        }
      };
    
  return (
    <div className={styles.wrapper}>
         <Toaster position='top-center' reverseOrder={false}></Toaster>
          <div className={styles.logo}>
           <img src={Logo} alt='logo'/> 
          </div>
          <div className={styles.header}>
            To Reset Your Password
          </div>
          <form className={styles.form} onSubmit={handleSubmit}>
             <div className={styles.inputdiv}>
                <input
                 type="text"
                  placeholder='Enter Email' 
                  className={styles.input}
                   onChange={handleEmailChange}/>
             </div>
             <div className={styles.btndiv}>
                 <button className={styles.btn}>Submit</button>
             </div>
          </form>
    </div>
  )
}


export default PasswordPage;