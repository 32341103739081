import { useLocation } from "react-router-dom";
import { useState } from "react";
import styles from "../components/styles/modal.module.css"
import { api } from "../api";
import { Toaster, toast } from 'react-hot-toast';
const Modal = ({closeModal}) => {

    const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');


  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
        setIsLoading(true);
     
        const response = await api.delete("/users/delete-account", { data: { email } });

        if (response && response.status === 204) {
            toast.success("User deleted successfully");
            closeModal(true); 
        } else {
            toast(response.data.message || 'An error occurred', { type: 'error' });
        }
    } catch (error) {
        console.error('Error:', error);
        toast.error(error.response?.data?.message || 'An error occurred', { type: 'error' });
    } finally {
        setIsLoading(false);
    }
};

    return(
        <div className={styles.modal}>
              <Toaster position="top-center" reverseOrder={false}></Toaster>
            <div className={styles.overlay} onClick={() => closeModal(false)}></div>
             <div className={styles.modalContent}>
              <div className={styles.titleCloseBtn}>
              <button onClick={() => closeModal(false)}>X</button>
              </div>
             <div className={styles.title}>
                    Warning
                </div>
                <div className={styles.body}>
                    <p>Do you want to proceed?</p>
                </div>
                <div className={styles.footer}>
                    <button disabled={isLoading} onClick={handleSubmit}>Yes</button>
                    <button onClick={() => closeModal(false)}>No</button>
                </div>
             </div>
                
            
        </div>
    )
}

export default Modal;