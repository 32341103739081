import React from 'react'
import styles from './styles/success.module.css';
import Logo from "../assets/cp365v.png"

 const SuccessPage = () => {
  return (
    <div className={styles.wrapper}>
        <div className={styles.logo}>
         <img src={Logo} alt='logo'/> 
        </div>
        <div className={styles.container}>
           <div className={styles.header}>
              <h1>Password Reset Successful</h1>
           </div>
           <div className={styles.p}>
           <p>Your password has been successfully reset.</p>
           <p>You can now use your new password to log in.</p>
           </div>
        </div>
    </div>
  )
}


export default SuccessPage;