
import React from 'react';
import { createBrowserRouter, RouterProvider} from 'react-router-dom';
import VerifyOtp from './components/verifyOtp';
import SuccessPage from './components/successPage';
import PasswordOrPin from './components/Password.pin';
import PasswordPage from './components/passwordPage';
import PinPage from './components/PinPage';
import ConfirmationPage from './components/confirmationPage';
import ResetPassword from './components/ResetPassword';
import ConfirmOtp from './components/confirmOtp';
import ResetPin from './components/ResetPin';
import VerifyUser from './components/verifyUser';
import SuccessPinPage from './components/successPinPage';
import Home from './components/home';
import DeleteAccount from './components/deleteAccount';
import ConfirmDeleteOtp from './components/confirmDeleteOtp';
import VerifyDeleteOtp from './components/verifyDeleteOtp';



const routes = createBrowserRouter([
      
      {
        path: "/verify",
        element: <VerifyOtp></VerifyOtp>
      },
      {
        path: "/new-password",
        element: <ResetPassword></ResetPassword>
      },
      {
        path: "/success",
        element: <SuccessPage></SuccessPage>
      },
      {
        path: "/success-pin",
        element: <SuccessPinPage></SuccessPinPage>
      },
      {
        path: "/",
        element: <Home></Home>
      },
      {
        path: "/delete-user",
        element: <DeleteAccount></DeleteAccount>
      },
      {
        path: "/reset",
        element: <PasswordOrPin></PasswordOrPin>
      },
      {
        path: "/password-reset",
        element: <PasswordPage></PasswordPage>
      },
      {
        path: "/pin-reset",
        element: <PinPage></PinPage>
      },
      {
        path: "/confirm",
        element: <ConfirmationPage></ConfirmationPage>
      },
      {
        path: "/confirm-delete",
        element: <ConfirmDeleteOtp></ConfirmDeleteOtp>

      },
      {
        path: "/confirm-otp",
        element: <ConfirmOtp></ConfirmOtp>
      },
      {
        path: "/reset-pin",
        element: <ResetPin></ResetPin>
      },
      {
        path: "/verify-user",
        element: <VerifyUser></VerifyUser>
      },
      {
        path: "/verify-delete",
        element: <VerifyDeleteOtp></VerifyDeleteOtp>
      },
])


const App = () => {
  return (
    <main>
       <RouterProvider router={routes}></RouterProvider>

    </main>

  );
};

export default App;

