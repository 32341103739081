import React from 'react';
import { Link } from 'react-router-dom';
import styles from "../components/styles/password.pin.module.css"
import Logo  from '../assets/cp365v.png';

 const PasswordOrPin = () => {
  return (
    <div className={styles.wrapper} >
      <div className={styles.logo}>
           <img src={Logo} alt="logo"/>
        </div>
         <div >
            <h2>What do you want to Reset</h2>
         </div>
         <form>
         <div className={styles.btndiv}>
           <Link to="/pin-reset">
           <button className={styles.btn}>Pin</button>
           </Link>
         </div>
         </form>
         <form>
         <div className={styles.btndiv}>
          <Link to="/password-reset">
          <button className={styles.btn}>Password</button>
          </Link>
          
         </div>
         </form>
                 
    </div>
  )
}


export default PasswordOrPin;