import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './styles/ResetPassword.module.css';
import Logo from '../assets/cp365v.png';
import { Toaster, toast } from 'react-hot-toast';

import { api } from '../api.js';

const ResetPin = () => {
  const navigate = useNavigate();
  const [pin, setPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const {state} = useLocation();


    // If state is available, set the OTP value
    const otp = state?.otp || '';


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (pin !== confirmPin) {
      toast('Pin do not match', { type: 'error' });
      return;
    }

    try {
      const response = await api.patch(
        `/users/pin/reset`,
        {otp, pin },
        {
          headers: {'Content-Type': 'application/json'}
        }
      );

      if (response && response.data) {
        if (response.data.status === true) {
          toast('Pin reset successfully!', { type: 'success' });
          // You can navigate to the success page or perform other actions here
          navigate('/success-pin');
        } else {
          toast(response.data.message || 'Pin reset failed', {
            type: 'error',
          });
        }
      } else {
        console.error('Invalid response format');
      }
    } catch (error) {
      console.error('Error:', error);

      toast(
        error.response?.data?.message || 'An error occurred during pin reset',
        { type: 'error' },
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <div className={styles.logo}>
        <img src={Logo} alt="logo" />
      </div>
      <div className={styles.header}><h2>Reset Pin</h2></div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.header}>
          <h4>Enter New Pin</h4>
        </div>
        <div className={styles.inputdiv}>
          <input
            type="text"
            placeholder="Pin"
            className={styles.input}
            value={pin}
            onChange={(e) => setPin(e.target.value)}
          />
        </div>
        <div className={styles.header}>
          <h4>Confirm Pin</h4>
        </div>
        <div className={styles.inputdiv}>
          <input
            type="text"
            placeholder="Confirm new pin"
            className={styles.input}
            value={confirmPin}
            onChange={(e) => setConfirmPin(e.target.value)}
          />
        </div>
        <div className={styles.btndiv}>
          <button className={styles.btn} type="submit">
             Reset Pin
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPin;

