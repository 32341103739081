export const env = {
  serverUrl: process.env.REACT_APP_API_BASE_URL,
  IS_DEV: process.env.NODE_ENV === "development",
  IS_STAGING: process.env.NODE_ENV === "staging",
  getEnv() {
    return {
      serverUrl: this.serverUrl,
      IS_DEV: this.IS_DEV,
      IS_STAGING: this.IS_STAGING,
    };
  },
};

export const Constants = {
  IS_DEV: process.env.NODE_ENV === "development",
  IS_STAGING: process.env.NODE_ENV === "staging",
};

  