import axios from 'axios';

const deployedUrl = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: deployedUrl,
});

api.interceptors.request.use(async (config) => {
    const token = await localStorage.getItem('token');
    if (token) {
      const accessToken = JSON.parse(token);
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }
  
    return config;
  });

  export {api}