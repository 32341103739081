import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import styles from "../components/styles/verify.module.css"
import logo from "../assets/cp365v.png"

 const ConfirmOtp= () => {


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');

  const navigate = useNavigate();

  const handleContinue = () => {
    
       navigate('/verify-user');
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
       <img src={logo}  alt='logo'/>
      </div>
        <div className={styles.container}>
            
            <div className={styles.p}>
              
              <p className={styles.boldText}> <span>If</span> {email}</p>
              <p> exists, check inbox.</p>
             
            </div>
            <form className={styles.form}>
              <button className={styles.btn} onClick={handleContinue}>Continue</button>
            </form>
        </div>
       

    </div>
  )
}

export default ConfirmOtp;
