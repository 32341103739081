import React, { useState} from 'react';
import {useNavigate} from "react-router-dom"
import { Toaster, toast } from 'react-hot-toast';
import { api } from '../api';
import styles from './styles/passwordPage.module.css';
import Logo from '../assets/cp365v.png';

const DeleteAccount = () => {
    const [email, setEmail] = useState('');
   const navigate = useNavigate()
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await api.post('/users/send-otp?type=deleteAccount', {email});

            if (response && response.status === 200) {
               navigate(`/confirm-delete?email=${email}`)
            } else {
                toast(response.data.message || 'An error occurred', { type: 'error' });
            }
        } catch (error) {
            console.error('Error:', error);
            toast(error.response?.data?.message || 'An error occurred', { type: 'error' });
        }
    };

    return (
        <div className={styles.wrapper}>
            <Toaster position="top-center" reverseOrder={false}></Toaster>
            <div className={styles.logo}>
                <img src={Logo} alt="logo" />
            </div>
            <div className={styles.header}>To delete your account enter your email</div>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.inputdiv}>
                    <input
                        type="text"
                        placeholder="Enter Email"
                        className={styles.input}
                        onChange={handleEmailChange}
                    />
                </div>
                <div className={styles.btndiv}>
                    <button type="submit" className={styles.btn}>
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default DeleteAccount;
