import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles/passwordPage.module.css';
import Logo from '../assets/cp365v.png';
import { Toaster, toast } from 'react-hot-toast';
import { env } from './../config/env.js';

const ConfirmationPage = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.patch(
        `${env.serverUrl}/users/verify`,
        { otp },
      );

      if (response && response.data) {

        if (response.data.status === true) {
          toast('OTP verified successfully!', { type: 'success' });
          navigate("/new-password", { state: { otp } });

        } else {
          toast(response.data.message || 'OTP verification failed', {
            type: 'error',
          });
        }
      } else {
        console.error('Invalid response format');
      }
    } catch (error) {
      console.error('Error:', error);

      toast(
        error.response?.data?.message || 'An error occurred during OTP verification',
        { type: 'error' },
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <div className={styles.logo}>
        <img src={Logo} alt="logo" />
      </div>
      <div className={styles.header}>Confirmation Pin</div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.inputdiv}>
          <input
            type="text"
            placeholder="Enter Confirmation Pin"
            className={styles.input}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </div>
        <div className={styles.btndiv}>
          <button className={styles.btn} type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ConfirmationPage;
