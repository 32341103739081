import React from 'react';
import { Link } from 'react-router-dom';
import styles from "../components/styles/home.module.css"
import Logo  from '../assets/cp365v.png';


 const Home = () => {





  return (
    <div className={styles.wrapper} >
      <div className={styles.logo}>
           <img src={Logo} alt="logo"/>
        </div>
         <div >
            <h2>What do you want to do today?</h2>
         </div>
         <form>
         <div className={styles.btndiv}>
            <Link to="/delete-user">
           <button className={styles.btn} >Delete Account</button>
           </Link>
    
         </div>
         </form>
         <form>
         <div className={styles.btndiv}>
          <Link to="/reset">
          <button className={styles.btn}>Reset</button>
          </Link>
          
         </div>
         </form>

  
                 
    </div>
  )
}


export default Home;